const windowWidth = $(window).width();
const documentHeight = $(document).height();

const options = {};

options.breakpoints = {
  'pc_contents': 1100,
  'pc': 1024,
  'tab': 768,
  'sp': 767
};

var ua, isIE, array, version, isSafari;
ua = window.navigator.userAgent.toLowerCase();

isIE = (ua.indexOf('msie') >= 0 || ua.indexOf('trident') >= 0);

isSafari = (ua.indexOf('safari') > -1) && (ua.indexOf('chrome') === -1);

window.lazySizesConfig = window.lazySizesConfig || {};

// lazyloadに合わせるとこう
window.lazySizesConfig.lazyClass = 'lazy';
// lazySizesConfig.srcAttr = 'data-original';

// 読み込みタイミングの調整
lazySizesConfig.loadMode = 1;
lazySizesConfig.expFactor = 4;

/** This is a description of the **** function. */
$(function() {

  if (navigator.userAgent.indexOf('Android') > 0) {
    $('body').addClass('android');
  }

  let headerCategory__item = $('.headerCategory__item>p');

  if (windowWidth <= options.breakpoints.pc_contents) {
    headerCategory__item.addClass('waves-effect waves-light');
    headerCategory__item.on('click', function() {
      console.log('click nav');
      $(this).toggleClass('active').parent().toggleClass('active');
    });
  } else {

    const sliderSetting = () => {
      let itemlength = headerCategory__item.length * 85;
      if (itemlength > windowWidth && windowWidth > options.breakpoints.pc_contents) {
        $('.headerCategory__list').addClass('js-headerCategory__list');
        $('.js-headerCategory__list').not('.slick-initialized').slick({
          slidesToShow: 13,
          infinite: false,
          slidesToScroll: 3,
        });
      } else {
        $('.headerCategory__list').removeClass('js-headerCategory__list');
        $('.js-headerCategory__list.slick-initialized').slick('unslick');
      }
    };

    sliderSetting();

    $('.js-header__menu').hover(
      function() {
        $(this).find('.headerCategory__title').toggleClass('active');
      },
      function() {
        $(this).find('.headerCategory__itemHidden').removeClass('active');
        $(this).find('.headerCategory__title').removeClass('active');
      }
    );

    headerCategory__item.removeClass('waves-effect waves-light');

    const itemHidden = $('.headerCategory__itemHidden');

    if (windowWidth > options.breakpoints.pc_contents) {
      let i = 1;
      itemHidden.each(function() {
        $(this).attr('data-number', i);
        $(this).parent().attr('data-number', i);
        $(this).insertAfter('.genre');
        i++;
      });
      $('.headerCategory__item').hover(
        function() {
          $('.headerCategory__itemHidden').removeClass('active');
          let number = $(this).data('number');
          let dataNumber = $('.headerCategory__itemHidden[data-number="' + number + '"]');
          dataNumber.addClass('active');
          $(this).addClass('active');
          setTimeout(function() {
            let height = dataNumber.height();
            height += 80;
            // $('.headerCategory__item:after').height(height);
          }, 100);
        },
        function() {
          // itemHidden.removeClass('active');
          $(this).removeClass('active');
        }
      );
    }
  }

  $('.js-pagetop').on('click', function() {
    $('body,html').animate({
      scrollTop: 0
    }, 500);
  });

  $('a[href^="#"]').on('click', function() {
    const speed = 200;
    const href = $(this).attr('href');
    const target = $(href == '#' || href == '' ? 'html' : href);
    let position = target.offset().top;
    $('html, body').animate({ scrollTop: position }, speed, 'swing');
    $('.header').addClass('hide');
    setTimeout(function() {
      $('.header').addClass('hide');
    }, 300);
    return false;
  });



  var menu = $('.humburger-menu'),
    menuBtn = $('.navButton'),
    body = $(document.body),
    menuWidth = menu.outerWidth();

  // $('html').removeclass('scroll-prevent') // 追記

  // menuBtn.on('click', function(){
  //   body.toggleClass('open');
  //   $('html').toggleClass('scroll-prevent')
  //   if(body.hasClass('open')){
  //     body.animate({'right' : menuWidth }, 200);
  //     menu.animate({'right' : 0 }, 200);
  //   } else {
  //     menu.animate({'right' : -menuWidth }, 200);
  //     body.animate({'right' : 0 }, 200);
  //   }
  // });


  let scrollTop = 0;
  $('.navButton').on('click', function() {
    if ($(this).hasClass('open')) {

      $('body').css({ 'position': 'static', 'top': '0' });
      ad.show();
      $('html,body').scrollTop(scrollTop);
    } else {
      scrollTop = $(window).scrollTop();
      $('body').css({ 'position': 'fixed', 'top': -scrollTop });
      // ad.hide();
    }
    $('body').toggleClass('open');
    $(this).toggleClass('open');
    $('.header').toggleClass('open');
  });

  $('#nav-input').prop('checked', false).change();

  window.onbeforeunload = function() {
    // IE
  };
  window.onunload = function() {
    // IE以外
  };
  window.onpageshow = function(event) {
    if (event.persisted) {
      location.reload();
    }
  };
  $('#nav-input').prop('checked', false).change();
  window.onpopstate = function() {
    $('#nav-input').prop('checked', false);
  };

  $(window).on('resize', function() {
    const windowWidthNow = $(window).width();
    if (windowWidthNow > options.breakpoints.pc_contents) {
      $('body').removeClass('open').css({ 'position': 'static', 'top': '0' });
    } else if ($('.navButton').hasClass('open')) {
      $('body').addClass('open');
    }
  });

  const ad = $('.ad');
  const adHeight = $('.ad').height();
  const headerHeight = $('.header__wrap').height();

  // if(windowWidth > options.breakpoints.pc) {
  //   $('main').css({'margin-top':adHeight + headerHeight});
  // }
  $('main').css({ 'margin-top': adHeight + headerHeight });


  if (!isIE) {
    let startPos = 0,
      winScrollTop = 0;
    $(window).on('scroll', function() {
      winScrollTop = $(this).scrollTop();
      let scrollPosition = $(window).height() + $(window).scrollTop();
      if (winScrollTop === 0) {
        $('main').css({ 'margin-top': adHeight + headerHeight });
        ad.fadeIn();
      } else if ($('.o-liveplayer__main').length && $('.o-liveplayer__main').hasClass('sp')) {
        $('.header').removeClass('hide');
      } else if (winScrollTop < headerHeight) {
        $('.header').removeClass('hide');
      } else if (winScrollTop > startPos) {
        $('.header').addClass('hide');
        $('main').css({ 'margin-top': headerHeight });
        ad.fadeOut();
      } else {
        $('.header').removeClass('hide');
      }
      startPos = winScrollTop;
      // if (isSafari && !$('.o-liveplayer__main').hasClass('fixed')) {
      // if (isSafari) {
      //   if ( (documentHeight - scrollPosition) / documentHeight <= 0.01) {
      //     $('.header').addClass('hide');
      //   }
      // }
    });
  }


  const popupImage = $('.popupImage');
  if (popupImage.length) {
    setTimeout(function() {
      const button = $('.popupImage__button');
      popupImage.fadeIn();
      button.on('click', function() {
        popupImage.fadeOut();
      });
    }, 3000);
  }

  $('.js-programChange').on('click', function() {
    $('.programChange--flex').fadeOut();
  });

  $('.inputFile__input').on('change', function() {
    let file_name = $(this).prop('files')[0].name;
    $(this).parent().next().html(file_name).addClass('active');
    if ($(this).hasClass('js-ageVerification')) {
      $(this).parents().find('.programChange__submit').removeClass('hidden');
    }
  });

  if ($('.wysiwygStyle .slideShow')) {
    $('.wysiwygStyle .slideShow').slick({ dots: true });
  }
});


// const u25 = () => {
//   var $window = $(window),
//     $html = $('html'),
//     $body = $('body'),
//     $overlay = $('.js-u25ModalOverlay'),
//     scrollbar_width = window.innerWidth - document.body.scrollWidth,
//     touch_start_y;

//   $window.on('touchstart', function(event) {
//     touch_start_y = event.originalEvent.changedTouches[0].screenY;
//   });

//   $('.modalOpen').on('click', function() {
//     $window.on('touchmove.noscroll', function(event) {
//       var overlay = $overlay[0],
//         current_y = event.originalEvent.changedTouches[0].screenY,
//         height = $overlay.outerHeight(),
//         is_top = touch_start_y <= current_y && overlay.scrollTop === 0,
//         is_bottom = touch_start_y >= current_y && overlay.scrollHeight - overlay.scrollTop === height;

//       if (is_top || is_bottom) {
//         event.preventDefault();
//       }
//     });

//     $('html, body').css('overflow', 'hidden');

//     if (scrollbar_width) {
//       $html.css('padding-right', scrollbar_width);
//     }

//     $overlay.fadeIn(300);
//   });

//   var closeModal = function() {
//     $body.removeAttr('style');
//     $window.off('touchmove.noscroll');

//     $overlay.animate({
//       opacity: 0
//     }, 300, function() {
//       $overlay.scrollTop(0).hide().removeAttr('style');
//       $html.removeAttr('style');
//     });
//   };

//   $overlay.on('click', function(event) {
//     console.log('o-u25Modal__overlay');
//     closeModal();
//     // if (!$(event.target).closest('.o-u25Modal__modal').length) {
//     //   closeModal();
//     // }
//   });

//   $('.js-u25Close').on('click', function() {
//     closeModal();
//   });
// };

/** This is a description of the **** function. */
/** オンデマンドカルーセル */
// function cardCarousel(){
const cardCarousel = () => {
  let windowWidthNow = $(window).width();

  // window.onload = function() {
  const slider = $('.js-cardCarousel');

  $('.cardCarousel__CardTitle a').each(function() {
    let textLength = $(this).text().length;
    let cutFigure = 0;
    cutFigure = '30';
    if (cutFigure < textLength) {
      // $(this).html(textTrim).css({visibility:'visible'});
      $(this).addClass('over');
    }
  });

  const _text = $('.cardCarousel__text a');
  _text.each(function() {
    let textLength = $(this).text().length;
    const cutFigure = '34';
    // let textTrim = $(this).text().substr(0,(cutFigure))
    if (cutFigure < textLength) {
      // $(this).html(textTrim).css({visibility:'visible'});
      $(this).addClass('over');
    }
  });

  $('.cardCarousel__CardTitle--webcastNow a').each(function() {
    let textLength = $(this).text().length;
    let cutFigure = 0;
    if (windowWidthNow < options.breakpoints.pc) {
      cutFigure = '30';
    } else {
      cutFigure = '40';
    }
    if (cutFigure < textLength) {
      $(this).addClass('over');
    }
  });

  $('.cardCarousel__text--webcastNow a').each(function() {
    let textLength = $(this).text().length;
    let cutFigure = 0;
    if (windowWidthNow < options.breakpoints.pc) {
      cutFigure = '34';
    } else {
      cutFigure = '50';
    }
    if (cutFigure < textLength) {
      $(this).addClass('over');
    }
  });

  // if (slider.hasClass('slick-initialized')) {
  //   slider.slick('unslick');
  // }

  slider.each(function() {
    // console.log('slider!!!')
    const _this = $(this);
    const sliderlength = _this.find('li').length;
    const width = windowWidthNow - 30,
      show = width / 232;

    if (_this.hasClass('slick-initialized')) {
      _this.slick('unslick');
    }

    if (windowWidthNow >= options.breakpoints.pc && sliderlength > show) {
      _this.addClass('slick-slider');
    }

    if (_this.hasClass('slick-slider')) {
      _this.not('.slick-initialized').slick({
        slidesToShow: show,
        slidesToScroll: 3,
        infinite: false,
        responsive: [{
          breakpoint: options.breakpoints.pc_contents,
          settings: 'unslick'
        }]
      });
    }
  });
  // };
};
window.onload = function() {
  cardCarousel();
}
















/** This is a description of the **** function. */
const heroOndemandCarousel = () => {
  if (windowWidth >= options.breakpoints.pc_contents) {
    $('.js-heroOndemandCarousel').not('.slick-initialized').slick({
      slidesToShow: 4.95495495495,
      slidesToScroll: 2,
      infinite: false,
      responsive: [{
        breakpoint: options.breakpoints.pc,
        settings: 'unslick'
      }]
    });
  }
};

/** This is a description of the **** function. */
const liveplayer = () => {
  const player = $('.o-liveplayer__main');
  // const player = $('.js-liveplayer');

  if (windowWidth <= options.breakpoints.pc_contents && windowWidth < window.innerHeight) {
    let content_height = $('.o-liveplayer').height();
    content_height -= 160;
    player.addClass('fixed');
    player.addClass('sp');
    $('.o-liveplayer__quality').appendTo(player);

    $(window).scroll(function() {
      let scroll_height = $(window).scrollTop();
      if (content_height < scroll_height) {
        player.addClass('hidden').removeClass('sp');
      } else {
        player.removeClass('hidden').addClass('sp');
      }
    });
    document.addEventListener('DOMFocusIn', function(event) {
      player.addClass('textEdit');
    }, false);
    document.addEventListener('DOMFocusOut', function(event) {
      player.removeClass('textEdit');
    }, false);
  }
  $('#ta')
    .focusin(function(e) {
      player.addClass('textEdit');
    })
    .focusout(function(e) {
      player.removeClass('textEdit');
    });

  let ta = document.getElementById('ta');
  ta.style.lineHeight = '18px';
  ta.style.height = '24px';

  ta.addEventListener('input', function(evt) {
    if (evt.target.scrollHeight > evt.target.offsetHeight) {
      evt.target.style.height = evt.target.scrollHeight + 'px';
    } else {
      var height, lineHeight;
      while (true) {
        height = Number(evt.target.style.height.split('px')[0]);
        lineHeight = Number(evt.target.style.lineHeight.split('px')[0]);
        evt.target.style.height = height - lineHeight + 'px';
        if (evt.target.scrollHeight > evt.target.offsetHeight) {
          evt.target.style.height = evt.target.scrollHeight + 'px';
          break;
        }
      }
    }
  });
};

/** This is a description of the **** function. */
const accordionButton = () => {

  // const accordion = $(".js-accordion__contents");

  let itemHeights = [];
  let returnHeight;

  window.onload = function() {
    const contents = $('.js-accordion__contents');
    contents.each(function() {
      const _this = $(this);
      let thisHeight = _this.height();
      itemHeights.push(thisHeight);
      _this.addClass('is-hide');
      returnHeight = _this.height();
    });
    if (contents.parent().hasClass('top')) {
      contents.height(145);
    } else if (windowWidth < options.breakpoints.tab) {
      contents.height(270);
    } else if (contents.hasClass('accordion__contents--channel')) {

    } else {
      contents.height(145);
    }

    $('.js-accordion__more').on('click', function() {
      const _this = $(this);
      let infoHeight = 145;

      if (_this.parent().hasClass('w-info__text') && windowWidth < options.breakpoints.tab && !contents.parent().hasClass('top')) {
        infoHeight = 270;
      }

      if (!_this.hasClass('is-show')) {
        let index = _this.index('.js-accordion__more');
        let h = itemHeights[index];
        _this.addClass('is-show').prev().animate({ height: h }, 200).removeClass('is-hide');
      } else {
        _this.removeClass('is-show').prev().animate({ height: infoHeight }, 200).addClass('is-hide');
      }
    });
  };
};

/** This is a description of the **** function. */
const accordionButton02 = () => {

  // const accordion = $(".js-accordion__contents");

  let itemHeights = [];
  let returnHeight;

  window.onload = function() {
    const contents = $('.js-accordion__contents--liveplayer');
    contents.each(function() {
      const _this = $(this);
      let thisHeight = _this.height();
      itemHeights.push(thisHeight);
      returnHeight = _this.height();
      if (thisHeight > 100) {
        _this.css('max-height', '80px');
        _this.after('<span class="accordion__more--black js-accordion__more"></span>');
      }
    });

    if (contents.parent().hasClass('top')) {
      contents.height(80);
    } else if (windowWidth < options.breakpoints.tab) {
      // contents.height(80);
    }

    $('.js-accordion__more').on('click', function() {
      const _this = $(this);
      let infoHeight = 80;

      if (_this.parent().hasClass('w-info__text') && windowWidth < options.breakpoints.tab && !contents.parent().hasClass('top')) {
        infoHeight = 80;
      }

      if (!_this.hasClass('is-show')) {
        let index = _this.index('.js-accordion__more');
        let h = itemHeights[index];
        console.log(h);
        _this.addClass('is-show').prev().animate({ maxHeight: h }, 200).addClass('is-show');
        console.log('open');
      } else {
        _this.removeClass('is-show').prev().animate({ maxHeight: infoHeight }, 200).removeClass('is-show');
      }
    });
  };
};

/** This is a description of the **** function. */
const realTimeButton = () => {
  $('.switch__label').on('click', function() {
    $('.o-realTime__lists').slideToggle();
  });
};

/** This is a description of the **** function. */
const listgroup = () => {
  $('#check').on('click', function() {
    $('#box').slideToggle(this.checked);
  });
  $('.listgroup__button').on('click', function() {
    $(this).toggleClass('active').next().slideToggle();
  });
};

/** This is a description of the **** function. */
const scrollMenu = () => {
  let leftOffset = $('.scrollMenu__item--active').offset().left;
  $('.scrollMenu__list').scrollLeft(leftOffset);
};

/** This is a description of the **** function. */
const result = () => {
  $('.js-resultButton').on('click', function() {
    if ($(this).hasClass('open')) {
      $(this).removeClass('open').parent().parent().next().slideToggle();
      $(this).children().text('絞り込み');
    } else {
      $(this).addClass('open').parent().parent().next().slideToggle();
      $(this).children().text('閉じる');
    }
  });
};


/** This is a description of the **** function. */
const info = () => {

  const _text = $('.w-info__text');

  _text.each(function() {
    const _this = $(this);
    const h = _this.height();
    if (h > 200) {
      _this.addClass('accordion');
      _this.wrapInner('<div class="accordion__contents--white js-accordion__contents" />');
      _this.append('<span class="accordion__more--white js-accordion__more"></span>');
    }
  });
};

/** This is a description of the **** function. */
const streamingText = () => {

  const headerHeight = $('.header__wrap').height();
  const streaming = $('.o-streaming');

  let startPos = 0,
    winScrollTop = 0;


  streaming.css({ 'top': headerHeight + 'px' });

  if (!isIE) {
    $(window).on('scroll', function() {
      winScrollTop = $(this).scrollTop();
      if (winScrollTop === 0) {
        streaming.css({ 'top': headerHeight + 'px' });
      } else if (winScrollTop < headerHeight) {
        streaming.css({ 'top': '0px' });
      } else if (winScrollTop < startPos) {
        streaming.css({ 'top': headerHeight + 'px' });
      } else {
        streaming.css({ 'top': '0px' });
      }
      startPos = winScrollTop;
    });
  }

  const list = $('.js-streamingText');
  const item = $('.js-streamingText > li');
  const strings = [];

  list.not('.slick-initialized').slick({
    arrows: false,
    dots: false,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    adaptiveHeight: true,
    autoplay: true,
    accessibility: false,
    rtl: true,
    autoplaySpeed: 6000,
  });

  if ($.cookie('bnrRead') != 'on') {
    streaming.removeClass('hidden');
  }
  $('.js-streaming__button').on('click', function() {
    streaming.addClass('hidden');
    $.cookie('bnrRead', 'on', { // cookieにbnrReadという名前でonという値をセット
      expires: 7, // cookieの有効日数
      path: '/' // 有効にするパス
    });
  });
};


const programPage = () => {
  $('.js-accordion__more').click(function() {
    const _this = $(this);
    _this.toggleClass('is-show').next().slideToggle();
  });

  $('.w-programPage__item').each(function() {
    const _this = $(this);
    let classVal = _this.get(0).className.split(' ')[1];
    _this.find('i').addClass('icon--' + classVal);
  });
};

const mainCarousel = () => {


  const width = windowWidth - 40,
    show = width / 696;

  $('.js-mainCarousel').not('.slick-initialized').slick({
    slidesToShow: 3,
    dots: true,
    variableWidth: true,
    centerMode: true,
    centerPadding: '10px',
    autoplay: true,
    autoplaySpeed: 7000,
    responsive: [{
      breakpoint: options.breakpoints.pc,
      settings: {
        arrows: false,
        variableWidth: false,
        slidesToShow: 1,
        centerPadding: '0px',
      }
    }]
  });
};

const rightMenu = () => {
  const box = $('.p-pressList__box');
  box.after('<div class="p-pressList__more"></div>');
  const more = $('.p-pressList__more');

  function remClass() {
    $(this).next(more).removeClass('active');
  }

  function adClass() {
    $(this).next(more).addClass('active');
  }



  for (let i = 0; i < box.length; i++) {
    const boxInnerH = $('.js-pressList').eq(i).innerHeight();
    const l = $('.js-pressList').children().length;
    let h = 0;
    $('.js-pressList').eq(i).children().each(function(index) {
      h += $(this).outerHeight();
      if (index === 4) {
        return false;
      }
    });
    if (l < 5) {
      more.eq(i).hide();
    } else {
      box.eq(i).css({
        height: h + 'px'
      });
    }



    more.on('click', function() {
      var index = more.index(this);
      var boxThis = box.eq(index);
      var innerH = $('.js-pressList').eq(index).innerHeight();

      if ($(this).hasClass('active')) {
        boxThis.animate({
          minHeight: '0px'
        }, 300, remClass);
      } else {
        boxThis.animate({
          minHeight: innerH
        }, 300, adClass);
      }
    });
  }
};

const pressText = () => {
  // const _text = $('.cardCarousel__text a');
  // _text.each(function(){
  //   let textLength = $(this).text().length;
  //   const cutFigure = '34';
  //   // let textTrim = $(this).text().substr(0,(cutFigure))
  //   if(cutFigure < textLength) {
  //     // $(this).html(textTrim).css({visibility:'visible'});
  //     $(this).addClass('over');
  //   }
  // });
  //
  // $('.cardCarousel__CardTitle--webcastNow a').each(function(){
  //   let textLength = $(this).text().length;
  //   let cutFigure = 0;
  //   if (windowWidthNow < options.breakpoints.pc) {
  //     cutFigure = '30';
  //   }else {
  //     cutFigure = '40';
  //   }
  //   if(cutFigure < textLength) {
  //     $(this).addClass('over');
  //   }
  // });
  $('.js-textcount--new a').each(function() {
    let textLength = $(this).text().length;
    let cutFigure = 0;
    if (windowWidth < options.breakpoints.pc) {
      cutFigure = '44';
    } else {
      cutFigure = '61';
    }
    if (cutFigure < textLength) {
      $(this).addClass('over');
    }
  });
  $('.js-textcount--newBig a').each(function() {
    let textLength = $(this).text().length;
    let cutFigure = 0;
    if (windowWidth < options.breakpoints.pc) {
      cutFigure = '44';
    } else {
      cutFigure = '61';
    }
    if (cutFigure < textLength) {
      $(this).addClass('over');
    }
  });
  $('.js-textcount--press a').each(function() {
    let textLength = $(this).text().length;
    let cutFigure = 0;
    if (windowWidth < options.breakpoints.pc) {
      cutFigure = '44';
    } else {
      cutFigure = '28';
    }
    if (cutFigure < textLength) {
      $(this).addClass('over');
    }
  });

  $('.js-textcount--rightNavi a').each(function() {
    let textLength = $(this).text().length;
    let cutFigure = 0;
    if (windowWidth < options.breakpoints.pc) {
      cutFigure = '44';
    } else {
      cutFigure = '28';
    }
    if (cutFigure < textLength) {
      $(this).addClass('over');
    }
  });
};

/** This is a description of the **** function. */
// function cardCarousel(){
const recommendedProduct = () => {
  let windowWidthNow = $(window).width();

  const slider = $('.js-recommendedProduct');

  let thisSite = null;

  if ($('body').hasClass('press')) {
    thisSite = 4;
  } else {
    thisSite = 6;
  }

  const sliderlength = slider.find('li').length;

  if (windowWidthNow >= options.breakpoints.pc && thisSite < sliderlength) {
    slider.addClass('slick-slider');
  }

  if (slider.hasClass('slick-slider')) {
    slider.not('.slick-initialized').slick({
      slidesToShow: thisSite,
      slidesToScroll: 4,
      infinite: false,
      responsive: [{
        breakpoint: options.breakpoints.pc_contents,
        settings: 'unslick'
      }]
    });
  }

};


/** コラム&ニュース用 おすすめ動画カルーセル */
const pressCardCarousel = () => {
  let windowWidthNow = $(window).width();

  const slider = $('.js-pressCardCarousel');

  let thisSite = null;

  if ($('body').hasClass('press')) {
    thisSite = 3;
  } else {
    thisSite = 6;
  }

  const sliderlength = slider.find('li').length;

  if (windowWidthNow >= options.breakpoints.pc && thisSite < sliderlength) {
    slider.addClass('slick-slider');
  }

  if (slider.hasClass('slick-slider')) {
    slider.not('.slick-initialized').slick({
      slidesToShow: thisSite,
      slidesToScroll: 3,
      infinite: false,
      responsive: [{
        breakpoint: options.breakpoints.pc_contents,
        settings: 'unslick'
      }]
    });
  }
};


/** This is a description of the **** function. */
// function cardCarousel(){
const characterPage = () => {

  $.fn.frameAnimation = function(options) {
    var setElm = this,
      defaults = {
        setWidth: 422, // フレーム幅
        frameSpeed: 100, // フレームスピード
        maxFrame: 17, // 最大コマ数
        delay: 1400, // 最大コマ数
        loop: true // ループ再生
      };
    var setting = $.extend(defaults, options);

    var frameTime = 0,
      setTimer = function() {
        var left = -(frameTime) * setting.setWidth;
        setElm.css({ backgroundPosition: left + 'px' + ' 0' });
        frameTime++;
        if (frameTime >= setting.maxFrame) {
          if (setting.loop) {
            frameTime = 0;
            setTimeout(setTimer, setting.delay);
          }
        } else {
          if (frameTime == 1) {
            setTimeout(setTimer, setting.frameSpeed + setting.delay);
          } else {
            setTimeout(setTimer, setting.frameSpeed);
          }
        }
      };
    setTimer();
  };

  if (windowWidth >= options.breakpoints.tab) {
    $('.w-character__heroImage--vicC').frameAnimation({
      setWidth: 422,
      frameSpeed: 4500,
      maxFrame: 2,
      delay: 500,
      loop: true
    });
    $('.w-character__heroImage--picC').frameAnimation({
      setWidth: 422,
      frameSpeed: 150,
      maxFrame: 17,
      delay: 2000,
      loop: true
    });
  } else {
    $('.w-character__heroImage--vicC').frameAnimation({
      setWidth: 211,
      frameSpeed: 4500,
      maxFrame: 2,
      delay: 500,
      loop: true
    });
    $('.w-character__heroImage--picC').frameAnimation({
      setWidth: 211,
      frameSpeed: 150,
      maxFrame: 17,
      delay: 2000,
      loop: true
    });
  }



  let windowWidthNow = $(window).width();

  const characterCarousel = $('.js-characterCarousel');
  const coloringCarousel = $('.js-coloringCarousel');

  const width = windowWidthNow,
    show = width / 232;

  characterCarousel.not('.slick-initialized').slick({
    slidesToScroll: 1,
    slidesToShow: 4.17,
    autoplaySpeed: 2000,
    autoplay: true,
    responsive: [{
        breakpoint: 1200,
        settings: {
          slidesToShow: 3.89,
        }
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2.3,
        }
      },
      {
        breakpoint: options.breakpoints.sp,
        settings: {
          slidesToShow: 2.3,
        }
      }
    ]
  });
  coloringCarousel.not('.slick-initialized').slick({
    slidesToScroll: 1,
    slidesToShow: 3,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [{
      breakpoint: options.breakpoints.sp,
      settings: {
        slidesToShow: 1,
      }
    }]
  });
};

const subscribeCatv = () => {
  if (windowWidth < options.breakpoints.pc) {
    $('.js-subscribeCatvNav > p').click(function() {
      $(this).toggleClass('active').prev('ul').slideToggle();
    });
    $('.subscribeCatv__navItem > p').click(function() {
      $(this).toggleClass('active').next('div').slideToggle();
    });
  }
};

const u25 = () => {
  var $window = $(window),
    $html = $('html'),
    $body = $('body'),
    $overlay = $('.js-u25ModalOverlay'),
    scrollbar_width = window.innerWidth - document.body.scrollWidth,
    touch_start_y;

  $window.on('touchstart', function(event) {
    touch_start_y = event.originalEvent.changedTouches[0].screenY;
  });

  $('.modalOpen').on('click', function() {
    $window.on('touchmove.noscroll', function(event) {
      var overlay = $overlay[0],
        current_y = event.originalEvent.changedTouches[0].screenY,
        height = $overlay.outerHeight(),
        is_top = touch_start_y <= current_y && overlay.scrollTop === 0,
        is_bottom = touch_start_y >= current_y && overlay.scrollHeight - overlay.scrollTop === height;

      if (is_top || is_bottom) {
        event.preventDefault();
      }
    });

    $('html, body').css('overflow', 'hidden');

    if (scrollbar_width) {
      $html.css('padding-right', scrollbar_width);
    }

    $overlay.fadeIn(300);
  });

  var closeModal = function() {
    $body.removeAttr('style');
    $window.off('touchmove.noscroll');

    $overlay.animate({
      opacity: 0
    }, 300, function() {
      $overlay.scrollTop(0).hide().removeAttr('style');
      $html.removeAttr('style');
    });
  };

  $overlay.on('click', function(event) {
    console.log('o-u25Modal__overlay');
    closeModal();
    // if (!$(event.target).closest('.o-u25Modal__modal').length) {
    //   closeModal();
    // }
  });
  $('.o-u25Modal').on('click', function(e){
    e.stopPropagation();
  });
  $('.js-u25Close').on('click', function() {
    closeModal();
  });
};

var current_button = '.o-liveplayer__quality--current button',
  quality_list = 'ul.o-liveplayer__qualityList',
  quality_text = current_button + ' span';

$(current_button + ', ' + quality_list + ' button').click(function() {
  $(current_button + ', ' + quality_list).toggleClass('active');
});
$(quality_list + ' button').click(function() {
  var current_quality = $(this).text();
  if (current_quality == '自動' || current_quality == '標準') {
    $(quality_text).addClass('short');
  } else {
    $(quality_text).removeClass('short');
  }

  $(quality_text).text(current_quality);
  $(quality_list + ' li').removeClass('current');
  $(this).parent().addClass('current');
});


const modalProgramChange = () => {
  // const programChange = document.querySelector('.programChange');
  // const programChangeBox = document.querySelector('.programChange__box');
  // const body = document.querySelector('body');
  // body.style.overflow = 'hidden';
  // function close() {
  //   programChange.style.display = 'none';
  // }
  // function closeProgramChange() {
  //   programChange.addEventListener('click', () => {
  //     close();
  //     body.style.overflow = 'auto';
  //   });
  //   programChangeBox.addEventListener('click', e => {
  //     e.stopPropagation();
  //   });
  // }
  // closeProgramChange();

  $('body').css('overflow','hidden');

  // $(function() {
  //   $('.js-programChange').on('click', function() {
  //     $('.programChange').fadeOut();
  //     $('body').css('overflow','auto');
  //   });
  // });
  const closeProgramChange = () => {
    $('.programChange').fadeOut();
    $('body').css('overflow', 'auto');
  }

  $(function() {
    $('.programChange').on('click', function() {
      closeProgramChange();
    });
    $('.programChange__box').on('click', function(e){
      e.stopPropagation();
    });
    $('.js-programChange').on('click', function() {
      closeProgramChange();
    });
  });

}
