$(function() {


  // Parsley.options.trigger = "keyup focusout change input";

  $.fn.autoKana('#familyName', '#familyName_kana', {
    katakana: true
  });
  $.fn.autoKana('#givenName', '#givenName_kana', {
    katakana: true
  });

  $('#familyName').keyup(function() {
    const familyName = $('#familyName_kana');
    if ( familyName.hasClass('parsley-error')) {
      familyName.removeClass('parsley-error').addClass('parsley-success');
      familyName.next().hide();
    }
  });

  $('#givenName').keyup(function() {
    const givenName = $('#givenName_kana');
    if ( givenName.hasClass('parsley-error')) {
      givenName.removeClass('parsley-error').addClass('parsley-success');
      givenName.next().hide();
    }
  });

  $('#postalCode').keyup(function() {
    const _adress = $('.p-region,.p-locality,.p-street-address');
    let _text = $(this).val().length;
    if ( _text == 7 && _adress.hasClass('parsley-error')) {
      _adress.removeClass('parsley-error').addClass('parsley-success');
      _adress.next().hide();
    }
  });

  $('.form').parsley().on('field:validated', function() {
    let ok = $('.parsley-error').length === 0;
    $('.bs-callout-info').toggleClass('hidden', !ok);
    $('.bs-callout-warning').toggleClass('hidden', ok);
  });

  $('.js-primaryForm input').change(function() {
    if ($('.js-etcRadio')[0].checked) {
      $('.js-etcTextarea').prop('disabled', false).prop('required', true);
    } else {
      $('.js-etcTextarea').prop('disabled', true).prop('required', false);
    }
  });
});
