/** This is a description of the **** function. */
$(function(){
  let os, ua = navigator.userAgent;

  const slider = $('.js-channel');

  if (slider.hasClass('slick-initialized')) {
    slider.slick('unslick');
  }
  slider.not('.slick-initialized').slick({
    slidesToShow: 7,
    dots: false,
    infinite: false,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: options.breakpoints.pc_contents,
        settings: "unslick"
      }
    ]
  });

  const wrap = $('.js-channel__wrap'),
        windowHeight = $(window).height(),
        menu = $('.js-channel__menu'),
        menuTop = menu.offset().top,
        iconmenu = $('.js-channel__bottom'),
        iconmenuTop = iconmenu.offset().top,
        iconmenuHidden = iconmenuTop - windowHeight,
        table = $('.js-channel__table'),
        thead = $('.js-channel__table > thead'),
        tbody = $('.js-channel__table > tbody'),
        th = $('.js-channel__th'),
        thHeight = thead.height(),
        headerHeight = $('.header').height();
  let menuHeight = menu.height();

  if(windowWidth <= options.breakpoints.pc_contents) {
  }
  table.css('padding-top', thHeight + 'px');
  wrap.css('top', menuHeight + 'px');
  iconmenu.css('margin-top', menuHeight + 'px');


  let startPos = 0,
      scroll = 0;

  function removeTransition(){
    menu.css('transition-duration', '0s');
    thead.css('transition-duration', '0s');
  }
  function addTransition(){
    menu.css('transition-duration', '.3s');
    thead.css('transition-duration', '.3s');
  }
  function removePadding(){
    menu.css('padding-top', '0px');
    thead.css('padding-top', '0px');
  }
  function addPadding(){
    menu.css('padding-top', headerHeight + 'px');
    thead.css('padding-top', headerHeight + 'px');
  }

  $(window).on('scroll',function(){
    scroll = $(window).scrollTop();

    if (scroll < menuTop) {
      menu.removeClass('fixed');
      thead.removeClass('fixed');
      thead.css('top', '0px');
      removePadding();
      removeTransition();
      menu.fadeIn();
      thead.fadeIn();
    }else if(scroll > iconmenuHidden && scroll >= startPos) {
      menu.fadeOut();
      thead.fadeOut();
      removeTransition();
    }else if (scroll > menuTop && scroll >= startPos){
      menu.addClass('fixed');
      thead.addClass('fixed');
      menu.fadeIn();
      thead.fadeIn();
      thead.css('top', menuHeight + 'px');
      removePadding();
      addTransition();
    }else if(scroll < iconmenuHidden) {
      addTransition();
      addPadding();
      menu.fadeIn();
      thead.fadeIn();
    }else if (scroll < iconmenuTop - 100){
      removeTransition();
      addPadding();
      menu.fadeIn(200);
      thead.fadeIn(200);
    }
    startPos = scroll;
    function remove(){
      thead.css('transition-duration', '0s');
    }
    setTimeout(remove, 300);
  });
  console.log(menuHeight);
  $(window).on('orientationchange', function(){
    if(Math.abs(window.orientation) === 90) {

      setTimeout(function(){
        menuHeight = menu.height();
        thead.css('top', '0px');
        wrap.css('top', menuHeight + 'px');
      }, 100);
    } else {
      setTimeout(function(){
        menuHeight = menu.height();
        thead.css('top', '0px');
        wrap.css('top', menuHeight + 'px');
      }, 100);
    }
  });

  table.on('scroll',function(){
    let scroll = this.scrollLeft;
    thead.css('left', '-' + scroll + 'px');
    // if (ua.match(/Android ([\.\d]+)/)) {
    //   $('.w-channel__head--time').css('left', '-' + scroll + 'px');
    // }
  });
});
