//グロナビの該当箇所をアクティブにする、初期位置調整
const loScrollMenu = () => { 
  var pageURL = location.pathname,
  pageURLArr = pageURL.split('/'), //パスを分割して配列化する
  pageURLArrCategory = pageURLArr[3]; //パスから第1階層を取得

  $('.lo__nav > ul > li > a').each(function (i, v) {
    var selfhref = $(v).attr('href'),
      hrefArr = selfhref.split('/'), //href属性の値を分割して配列化する
      hrefArrCategory = hrefArr[3]; //href属性の第1階層を取得

    //パスの第1階層とhref属性の第1階層を比較して同じ値であればcurrentを付与する
    if (pageURLArrCategory === hrefArrCategory) {
      $(v).parent().addClass('is-current');
    }
  });

  //ヘッダー初期位置調整
  // var myTarget = $(".is-current");
  // var targetPosition = (myTarget).offset().left;
  // $('.lo__nav').scrollLeft(targetPosition);
};

//ページ内リンクがヘッダーと被らないようにするための調整
const PageLink = () => {
  var headerHeight = $('.header__wrap').outerHeight(); // ヘッダーについているクラス名
  var urlHash = location.hash;
  if(urlHash) {
      $('body,html').stop().scrollTop(0);
      setTimeout(function(){
          var target = $(urlHash);
          var position = target.offset().top - headerHeight;
          $('body,html').stop().animate({scrollTop:position}, 600); // スクロールの速さ
      }, 100);
  }
  $('a[href^="#"]').click(function() {
      var href= $(this).attr("href");
      var target = $(href);
      var position = target.offset().top - headerHeight;
      $('body,html').stop().animate({scrollTop:position}, 600); // スクロールの速さ
  });
};

//トップページカルーセル
const topCardCarousel = () => {
  let windowWidthNow = $(window).width();

  window.onload = function() {
    const slider = $('.lo-top__video .js-cardCarousel');

    $('.cardCarousel__CardTitle a').each(function() {
      let textLength = $(this).text().length;
      let cutFigure = 0;
      cutFigure = '30';
      if(cutFigure < textLength) {
        // $(this).html(textTrim).css({visibility:'visible'});
        $(this).addClass('over');
      }
    });

    const _text = $('.cardCarousel__text a');
    _text.each(function() {
      let textLength = $(this).text().length;
      const cutFigure = '34';
      // let textTrim = $(this).text().substr(0,(cutFigure))
      if(cutFigure < textLength) {
        // $(this).html(textTrim).css({visibility:'visible'});
        $(this).addClass('over');
      }
    });

    $('.cardCarousel__CardTitle--webcastNow a').each(function() {
      let textLength = $(this).text().length;
      let cutFigure = 0;
      if (windowWidthNow < options.breakpoints.pc) {
        cutFigure = '30';
      }else {
        cutFigure = '40';
      }
      if(cutFigure < textLength) {
        $(this).addClass('over');
      }
    });

    $('.cardCarousel__text--webcastNow a').each(function() {
      let textLength = $(this).text().length;
      let cutFigure = 0;
      if (windowWidthNow < options.breakpoints.pc) {
        cutFigure = '34';
      }else {
        cutFigure = '50';
      }
      if(cutFigure < textLength) {
        $(this).addClass('over');
      }
    });

    // if (slider.hasClass('slick-initialized')) {
    //   slider.slick('unslick');
    // }

    slider.each(function() {
      // console.log('slider!!!')
      const _this = $(this);
      const sliderlength = _this.find('li').length;
      const width = 1100,
        show = width / 235;

      if (_this.hasClass('slick-initialized')) {
        _this.slick('unslick');
      }

      if (windowWidthNow >= options.breakpoints.pc && sliderlength > show ) {
        _this.addClass('slick-slider');
      }

      if (_this.hasClass('slick-slider')) {
        _this.not('.slick-initialized').slick({
          slidesToShow: show,
          slidesToScroll: 3,
          infinite: false,
          responsive: [
            {
              breakpoint: options.breakpoints.pc_contents,
              settings: 'unslick'
            }
          ]
        });
      }
    });
  };
};

const detailLoadWidget = () => {
  //ウィジェットの読み込みが終わった後に表示しないタブをdisplay:none;にする、表示するタブをdisplay:block;にする
  setTimeout(function() {
    $('.lo-result__widgetPanel').css('display', 'none');
    $('.lo-result__widgetPanel' + '.is-show').css('display', 'block');
  }, 3500);

  $('.lo-result__widgetTab-item').click(function(){
		$('.is-active').removeClass('is-active');
		$(this).addClass('is-active');
		$('.is-show').removeClass('is-show').css('display', 'none');
        // クリックしたタブからインデックス番号を取得
		const index = $(this).index();
        // クリックしたタブと同じインデックス番号をもつコンテンツを表示
		$('.lo-result__widgetPanel').eq(index).addClass('is-show').css('display', 'block');
	});
}

const rankingLoadWidget = () => {
  //ウィジェットの読み込みが終わった後に表示しないタブをdisplay:none;にする、表示するタブをdisplay:block;にする
  setTimeout(function() {
    $('.lo-ranking__widgetPanel').css('display', 'none');
    $('.lo-ranking__widgetPanel' + '.is-show').css('display', 'block');
  }, 3500);

  $('.lo-ranking__widgetTab-item').click(function(){
		$('.is-active').removeClass('is-active');
		$(this).addClass('is-active');
		$('.is-show').removeClass('is-show').css('display', 'none');
        // クリックしたタブからインデックス番号を取得
		const index = $(this).index();
        // クリックしたタブと同じインデックス番号をもつコンテンツを表示
		$('.lo-ranking__widgetPanel').eq(index).addClass('is-show').css('display', 'block');
	});
}