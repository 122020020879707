
let ytWidth = 1000;
let ytHeight = 562;

if (windowWidth < options.breakpoints.pc) {
  ytWidth = windowWidth,
  ytHeight = windowWidth * 0.5625;
}

let ytPlayer = [];
let ytPlaying, ytStop, ytPlay;

var tag = document.createElement('script');
tag.src = 'https://www.youtube.com/iframe_api';
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

function onYouTubeIframeAPIReady() {
  const playerLength = $('.youtubePlayer').length;
  $('.youtubePlayer').each(function(i) {

    $(this).attr('id', 'player' + (i + 1));
    $(this).attr('playerId', i);

    let thisId = $(this).data('id');

    let thisArea = $(this).attr('id');

    if ($('.heroCarousel__item--youtube').eq(i).hasClass('slick-center')) {

      ytPlayer[i] = new YT.Player(thisArea, {
        height: ytHeight,
        width: ytWidth,
        videoId: thisId,
        playerVars: {
          controls: 0,
          disablekb: 0,
          fs: 0,
          iv_load_policy: 3,
          modestbranding: 1,
          playsinline: 1,
          rel: 0,
          showinfo: 0,
          autoplay: 1,
          loop: 1,
        },
        events: {
          'onStateChange': onPlayerStateChange,
          'onReady': onPlayerReady
        }
      });

    } else {
      ytPlayer[i] = new YT.Player(thisArea, {
        height: ytHeight,
        width: ytWidth,
        videoId: thisId,
        playerVars: {
          controls: 0,
          disablekb: 0,
          fs: 0,
          iv_load_policy: 3,
          modestbranding: 1,
          playsinline: 1,
          rel: 0,
          showinfo: 0,
          autoplay: 0,
          loop: 1,
        },
        events: {
          'onStateChange': onPlayerStateChange,
          'onReady': onPlayerReady
        }
      });
    }
  });
}

function onPlayerReady(event) {
  event.target.mute();
}
// プレーヤーの状態に変化があった時に実行
function onPlayerStateChange(event) {
  var ytStatus = event.target.getPlayerState();
  if (ytStatus == YT.PlayerState.ENDED) {
    $('.js-heroCarousel').slick('slickNext');
  }

}

/** This is a description of the **** function. */
const heroCarousel = () => {
  const title = $('.js-heroCarousel__titles');
  const list = $('.heroCarousel__list');
  const mainSlider = $('.js-heroCarousel');


  mainSlider.not('.slick-initialized').slick({
    slidesToShow: 1,
    dots: false,
    variableWidth: true,
    centerMode: true,
    asNavFor: '.js-heroCarousel__titles',
    autoplay: true,
    autoplaySpeed: 7000,
    responsive: [
      {
        breakpoint: options.breakpoints.pc,
        settings: {
          arrows: false,
          variableWidth: false,
          slidesToShow: 1,
          centerPadding: '0px',
        }
      }
    ]
  });
  title
    .on('init', function(event, slick) {
      if ($('.slick-active').hasClass('heroCarousel__item--youtube')) {

        let size = $('.youtubePlayer').length;
        let number = $('.slick-active .youtubePlayer').attr('playerId');

        if($('.js-heroCarousel li').eq(2).hasClass('heroCarousel__item--youtube')) {
          $('.js-heroCarousel').slick('slickPause');
        }

      // ytPlayer[0].playVideo();
      // if (2 >= size) {
      //   ytPlayer[0].playVideo();
      // }else {
      //   ytPlayer[number].playVideo();
      // }
      }
    })
    .not('.slick-initialized').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      asNavFor: '.js-heroCarousel',
      dots: true,
      fade: true,
      focusOnSelect: true,
      arrows: false,
      responsive: [
        {
          breakpoint: options.breakpoints.pc,
          settings: {
            arrows: false,
          }
        }
      ]
    })
    .on('beforeChange', function(event, slick, currentSlide, nextSlide) {

      $('.heroCarousel__titleArea p').animate({opacity: 0}, 10);
      list.addClass('hidden');
      if ($('.slick-active').hasClass('heroCarousel__item--youtube')) {
        let size = $('.youtubePlayer').length;
        let number = $('.slick-active .youtubePlayer').attr('playerId');

        for ( var i = 0; i < ytPlayer.length; i++ ) {
          ytPlayer[i].stopVideo();
        }
      }
    })
    .on('afterChange', function(event, slick, currentSlide, nextSlide) {
      $('.heroCarousel__titleArea p').animate({opacity: 1}, 500);
      list.removeClass('hidden');
      if ($('.slick-active').hasClass('smallBg')) {
        list.addClass('smallBg');
      } else {
        list.removeClass('smallBg');
      }
      $('.js-heroCarousel').slick('slickPlay');
      if ($('.slick-active').hasClass('heroCarousel__item--youtube')) {
        let size = $('.youtubePlayer').length;

        let number = $('.slick-active .youtubePlayer').attr('playerId');

        if (size <= 2) {
          ytPlayer[0].stopVideo();
          ytPlayer[0].playVideo();
        }else {
          ytPlayer[number].stopVideo();
          ytPlayer[number].playVideo();
        }
        $('.js-heroCarousel').slick('slickPause');
      }
    });

  // console.log(ytPlayer[0]);
  // console.log(ytPlayer.length);
  // ytPlayer[0].stopVideo();
  // ytPlayer[0].playVideo();


  $('.heroCarousel__iconArea').each(function() {
    let iconArea = $(this).children().length;
    if (iconArea >= 5) {
      $(this).addClass('over5');
    }
  });
};
